import React from "react";
import { Paper, Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import Currency from "../Currency";

const TotalDueCard = ({ remainingBalance }) => {
  const classes = useStyles();

  return (
    <Paper elevation={4} className={clsx(classes.paperContainer)}>
      <Grid item xs={12} className={clsx(classes.flexCenter)}>
        <div
          className={clsx(classes.bigLabel, "total-due")}
          data-value={remainingBalance}
        >
          Total Due:
          <span className={clsx(classes.paddingLeft, classes.bigValue)}>
            <Currency value={remainingBalance}></Currency>
          </span>
        </div>
      </Grid>
    </Paper>
  );
};

export default TotalDueCard;
