import React from "react";
import { useStyles } from "./styles";
import { Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import MobilePaymentService from "../../services/MobilePaymentService";
import apiClient from "../../auth/apiClient";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import { isEmpty } from "lodash";
import clsx from "clsx";

const mobilePayService = new MobilePaymentService(apiClient);

export const sendReceiptSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("* Required"),
});

const EmailRecieptForm = ({ ticketId }) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();

  const sendReceipt = async (values) => {
    if (!values.email || isEmpty(values.email)) {
      enqueueSnackbar("An email address must be provided.", {
        variant: "warning",
        tag: "badEmail",
      });
      return;
    }

    try {
      await mobilePayService.emailReceipt(ticketId, values.email);
      enqueueSnackbar("Successfully sent receipt", { variant: "success" });
    } catch {
      enqueueSnackbar("Failed to send email", { variant: "error", tag: "failToSendEmail"  });
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={sendReceiptSchema}
      onSubmit={async (values) => {
        await sendReceipt(values);
      }}
    >
      {({ submitForm, handleChange }) => (
        <Form className={clsx(classes.flexCenter, classes.flexColumn, classes.spacing, "email-receipt-form")}>
          <Field
            component={TextField}
            className={clsx(classes.receiptEmailInput, "receipt-email-field")}
            variant="outlined"
            name="email"
            data-id="receipt-email"
            label="Email Address"
            disabled={false}
            onChange={handleChange}
          />
          <Button
            variant="contained"
            className={clsx(classes.receiptButton, "send-receipt-button")}
            color="primary"
            aria-label={"send-receipt-button"}
            title="Email Receipt"
            onClick={submitForm}
          >
            Send Receipt
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default EmailRecieptForm;
