import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  offerTitle: {
    "& .MuiTypography-gutterBottom": {
      margin: 0,
    },
  },
  drawerContainer: {
    padding: theme.spacing(1),
  },
  drawerItems: {
    padding: theme.spacing(2),
  },

  btnControl: {
    margin: theme.spacing(1),
    float: "right",
  },
  selectError: {
    color: "#e70022",
    marginLeft: "10px",
    fontSize: ".85em",
    marginTop: "5px",
  },
  flexInput: {
    flexGrow: "1",
  },
  footerContainer: {},
  listContainer: {
    width: "100%",
  },
  listEditIcon: {
    marginRight: theme.spacing(2),
  },
  listLogoPagination: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  bannerImage: {
    height: "200px",
  },
  logoListContainer: {
    height: "calc(100vh - 200px)",
    overflowY: "auto",
  },
  errorMessage: {
    color: "#e70022",
    paddingLeft: theme.spacing(2),
  },
  footerButtons: {
    display: "flex",
    position: "absolute",
    bottom: 0,
  },
  footerDivider: {
    position: "absolute",
    bottom: "70px",
    width: "100%",
  },
}));
