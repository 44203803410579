import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1),
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
    textAlign: "center",
  },
  subtitle: {
    fontSize: "small",
    fontStyle: "italic",
    fontWeight: "light",
    textAlign: "center",
  },
  termsContainer: {
    overflowX: "hidden",
    overflowWrap: "break-word",
  },
  subHeader: {
    fontWeight: "bold",
    margin: theme.spacing(0.5),
  },
  text: {
    whiteSpace: "pre-wrap",
    margin: theme.spacing(0.1),
    textAlign: "left",
    backgroundColor:theme.palette.background.paper,
  },
  modalButton: {
    color: theme.palette.text.primary,
    textDecoration: "underline",
  },
  contactInfo: {
    listStyleType: "none",
  }
}));
