import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  textEnd: {
    textAlign: "end",
  },
  value: {
    color: theme.palette.primary?.main,
  },
  label: {
    color: theme.palette.text.secondary,
  },
  ticketId: {
    color: theme.palette.primary?.main,
    fontWeight: "bold",
  },
  paddingLeft: {
    paddingLeft: "1rem",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  paperContainerLarge: {
    padding: "1.5rem 1rem",
  },
  paperContainer: {
    padding: "1rem",
  },
  spacing: {
    padding: "1rem 0",
  },
  accordingLabel: {
    color: theme.palette.grey?.main,
  },
  bigLabel: {
    color: theme.palette.grey?.main,
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
  bigValue: {
    color: theme.palette.primary.dark,
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
  payNowButton: { width: "100%", fontSize: "1.3rem" },
  termsAndConditions: { paddingBottom: "1rem" },
  flexColumn: { flexDirection: "column" },
  receiptButton: { marginTop: "1rem" },
  warningIcon: {
    color: theme.palette.error?.main,
    fontSize: "18pt",
    marginRight: "1rem",
  },
  timelineDetails: {
    display: "flex",
    justifyItems: "center",
    gap: "0.25rem",
    flexDirection: "row-reverse",
  },
  styledTimelineDot: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.light,
  },
  timelineItemDescription: {
    color: theme.palette.text.secondary,
    fontSize: ".80rem",
  },
  errorContainer: {
    color: theme.palette.grey?.main,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    textAlign: "center",
  },
}));
