import React from "react";
import {
  Button,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Box,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useStyles } from "../styles";
import { faTrashCan, faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "@material-ui/lab/Pagination";

const ListLogosAndbanners = ({
  onClose,
  logosAndBanners,
  editLogo,
  deleteLogo,
  listLogoBannerPageChange,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.logoListContainer}>
          <List>
            {logosAndBanners.collection.map((item) => (
              <ListItem key={item.mediaId}>
                <ListItemText primary={item.logoName} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    color="primary"
                    onClick={() => editLogo(item)}
                    data-testid="logo-edit-icon"
                    className={classes.listEditIcon}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    color="primary"
                    onClick={() => deleteLogo(item.mediaId)}
                    data-testid="logo-delete-icon"
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
        {logosAndBanners.totalCount > 10 && (
          <Grid item container justify="flex-end" xs={12}>
            <Pagination
              color="primary"
              shape="rounded"
              size="small"
              showFirstButton
              showLastButton
              count={Math.ceil(logosAndBanners.totalCount / 10)}
              className={classes.listLogoPagination}
              onChange={listLogoBannerPageChange}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Box className={classes.footerDivider}>
            <Divider />
          </Box>
        </Grid>
        <Grid item container justify="flex-end">
          <Box className={classes.footerButtons} mr={2} mb={2}>
            <Button name="close" variant="contained" onClick={onClose}>
              Close
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

ListLogosAndbanners.defaultProps = {
  onClose: () => {},
  logosAndBanners: { collection: [], totalCount: 0 },
  editLogo: () => {},
  deleteLogo: () => {},
};

ListLogosAndbanners.propTypes = {
  onClose: PropTypes.func.isRequired,
  editLogo: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
  logosAndBanners: PropTypes.shape({
    collection: PropTypes.arrayOf(
      PropTypes.shape({
        deviceId: PropTypes.string,
        entityId: PropTypes.string,
        logoName: PropTypes.string,
        mediaId: PropTypes.string,
        timeout: PropTypes.number,
      })
    ).isRequired,
    totalCount: PropTypes.number.isRequired,
  }).isRequired,
};

export default ListLogosAndbanners;
