import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { convertToRaw, convertFromRaw } from "draft-js";
import { convertFromHTML, convertToHTML  } from "draft-convert";
import { makeStyles } from "@material-ui/core/styles";
import MUIRichTextEditor from "mui-rte";
import { useStyles } from "./styles";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import clsx from "clsx";

const defaultStyle = {
  fontSize: 1,
  currentText: "",
  alignment: "left",
};

const fontRegex = "(font-size:\\s?)\\d+(\\.\\d{1,})?em";
const alignmentRegex = "(text-align:\\s?)(left|center|right)";
const nonDigitRegex = /[^0-9.]/g;
const alignmentValueRegex = /(left|center|right)/g;

const useEditorStyles = (props) =>
  makeStyles((theme) => ({
    fontStyle: {
      color: theme.palette.white?.main,
      fontSize: `${props.fontSize}em !important`,
      display: "block",
      textAlign: `${props.alignment}`,
    },
  }));

const RichTextEditor = ({ content, disabled, editorLabel, onChange, customEditorStyles }) => {
  const classes = useStyles();
  const [richTextContent, setRichTextContent] = useState();
  const [customStyle, setCustomStyle] = useState(defaultStyle);
  const [hasBeenFocused, setHasBeenFocused] = useState(false);
  const editorStyles = useEditorStyles(customStyle)();

  useEffect(() => {
    if (content && content !== "") {
      let fontSize;
      let alignment;
      let matches;
      matches = content.match(fontRegex);
      if (matches) {
        fontSize = parseFloat(matches[0].replace(nonDigitRegex, ""), 10);
      }

      matches = content.match(alignmentRegex);
      if (matches) {
        const values = matches[0].match(alignmentValueRegex);
        if (values) alignment = values[0];
      }

      setCustomStyle((prev) => ({ ...prev, alignment, fontSize }));
    }
    const stripLineFeeds = content.replace(new RegExp('\n', 'g'), "");
    const contentHTML = convertFromHTML(stripLineFeeds);
    const rawState = convertToRaw(contentHTML);
    const updatedContent = JSON.stringify(rawState);
    setRichTextContent(updatedContent);
  }, [content]);

  function handleConvert(styles) {
    let textContent = styles.currentText;
    let textAlignValue = styles.alignment;
    let fontSizeNum = styles.fontSize;
    return `<div style="text-align:${textAlignValue};font-size:${fontSizeNum}em;">${textContent}</div>`;
  }

  const increaseFontSize = () => {
    setCustomStyle((prevStyle) => { 
      const newStyle = { ...prevStyle };
      newStyle.fontSize = prevStyle.fontSize + 0.5;
      handleRateDisplayChange(newStyle);
      return newStyle;
    });
  };

  const decreaseFontSize = () => {
    setCustomStyle((prevStyle) => { 
      const newStyle = { ...prevStyle };
      newStyle.fontSize = prevStyle.fontSize - 0.5;
      handleRateDisplayChange(newStyle);
      return newStyle;
    });
  };

  const handleInputChange = (data) => {
    const textContent = convertToHTML(data.getCurrentContent());
    const contentState = convertFromRaw(convertToRaw(convertFromHTML("<div>" + textContent + "</div>")));
    const htmlText = convertToHTML(contentState);
    setCustomStyle((prevStyle) => { 
      const newStyle = { ...prevStyle };
      newStyle.currentText = htmlText;
      triggerChangeHandler(newStyle, hasBeenFocused);
      return newStyle;
    });
  };

  const changeAlignment = (align) => {
    setCustomStyle((prevStyle) => { 
      const newStyle = { ...prevStyle };
      newStyle.alignment = align;
      handleRateDisplayChange(newStyle);
      return newStyle;
    });
  };

  const triggerChangeHandler = (updatedStyle, focusState) => {
    const messageString = handleConvert(updatedStyle);
    onChange(messageString, focusState);
  }

  const handleFocus = () => {
    setHasBeenFocused(true);
  };

  const handleRateDisplayChange = display => {
    setHasBeenFocused((prevFocusStatus) => {
      triggerChangeHandler(display, true);
      return true;
    })
  }

  return (
      <MUIRichTextEditor
        readOnly={disabled}
        classes={{
          editorContainer: clsx("richTextEditor",
            customEditorStyles.editorContainer? customEditorStyles.editorContainer: classes.editorContainer,
            editorStyles.fontStyle
          ),
          toolbar: customEditorStyles.toolbar? customEditorStyles.toolbar: classes.toolbar,
          container: customEditorStyles.container? customEditorStyles.container: classes.container,
        }}
        defaultValue={richTextContent}
        label={editorLabel}
        controls={[
          "leftAlign",
          "centerAlign",
          "rightAlign",
          "increaseText",
          "decreaseText",
        ]}
        onChange={handleInputChange}
        onFocus={handleFocus}
        customControls={[
          {
            name: "leftAlign",
            icon: <FormatAlignLeftIcon className={clsx("leftAlignBtn", classes.icons)} />,
            type: "callback",
            onClick: () => {
              changeAlignment("left");
            },
          },
          {
            name: "centerAlign",
            icon: <FormatAlignCenterIcon className={clsx("centerAlignBtn", classes.icons)} />,
            type: "callback",
            onClick: () => {
              changeAlignment("center");
            },
          },
          {
            name: "rightAlign",
            icon: <FormatAlignRightIcon className={clsx("rightAlignBtn", classes.icons)} />,
            type: "callback",
            onClick: () => {
              changeAlignment("right");
            },
          },
          {
            name: "increaseText",
            icon: <AddIcon className={clsx("increaceTextBtn", classes.icons)} />,
            type: "callback",
            onClick: () => {
              increaseFontSize();
            },
          },
          {
            name: "decreaseText",
            icon: <RemoveIcon className={clsx("decreaseTextBtn", classes.icons)} />,
            type: "callback",
            onClick: () => {
              decreaseFontSize();
            },
          },
        ]}
      />
  );
};

RichTextEditor.defaultProps = {
  onChange: () => {},
  content: "",
  disabled: false,
  editorLabel: "Rich Text Editor",
  customEditorStyles: {}
};

RichTextEditor.propTypes = {
  content: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  editorLabel: PropTypes.string,
  customEditorStyles: PropTypes.shape({
    container: PropTypes.object,
    toolbar: PropTypes.object,
    editorContainer: PropTypes.object,
  }),
};

export default RichTextEditor;

