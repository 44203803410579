import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  icons: {
    fill: theme.palette.white?.main,
  },
  toolbar: {
    position: "relative",
    marginTop: "50px",
    textAlign: "center",
  },
  editorContainer: {
    width: "calc(100% - 40px) !important",
    marginLeft: "20px !important",
    maxHeight: "331px",
    overflow: "hidden",
  },
}));
