import React, { useEffect } from 'react';
import { useStyles } from './styles';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { TERMS_AND_CONDITIONS } from './terms';
import { isUndefined } from 'lodash';
import FacilityService from "../../../services/FacilityService";
import apiClient from "../../../auth/apiClient";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";

const facilityService = new FacilityService(apiClient);

const TermsAndConditions = ({ ticketId }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [facilityData, setFacilityData] = React.useState({
    name: "",
    address: {},
    phone: "",
    email: "",
  });
  
  const handleOpen = () => {
    setIsOpen(true);
  };
  
  const handleClose = () => {
    setIsOpen(false);
  };
  
  useEffect(() => {
    facilityService
      .getFacilityByTicket(ticketId)
        .then((res) => {
          setFacilityData({
            name: res.data?.name ?? "",
            address: res.data?.address ?? {},
            phone: res.data?.phone ?? "",
            email: res.data?.email ?? "",
          });
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);
  
  return (
    <>
      <Link
        component="button"
        variant="body2"
        onClick={handleOpen}
        className={clsx(["terms-and-conditions", classes.modalButton])}
      >
        Terms and Conditions
      </Link>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        onClick={handleClose}
      >
        <DialogTitle className={clsx(["title", classes.title])}>
          <Grid container>
            <Grid item xs={12}>
              Terms and Conditions
            </Grid>
            <Grid item xs={12} className={clsx(["subtitle", classes.subtitle])}>
              (Tap to Close)
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={clsx(["dialog-content", classes.termsContainer])}>
          <TermsContent
            classes={classes}
            termsAndConditions={TERMS_AND_CONDITIONS}
            facilityData={facilityData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TermsAndConditions;

/**
 * The structure of a terms and conditions sheet. Iterates through the sub sections with the
 * constant names as the keys/subheaders. Separate for testing.
 * @param classes: pass down the classes from the useStyles hook elsewhere, so this can be simpler
 * @param termsAndConditions: has a lastUpdated property, then keys such as AGREEMENT_TO_TERMS which 
 * hold objects you can traverse all tree-like: object = { text: "", children: [<object>]}
 * @param facilityData
 * @returns {JSX.Element}
 * @constructor
 */
const TermsContent = ({ classes, termsAndConditions, facilityData }) => (
  <>
    <h2 className={clsx(["terms-subheader", classes.subHeader])}>TERMS OF USE</h2>
    <h3 className={clsx(["last-updated-subheader", classes.subHeader])}>Last Updated {termsAndConditions.lastUpdated ?? ""}</h3>
    {Object.keys(termsAndConditions).map(sectionHeader => {
      return (
        <>
          {!isUndefined(termsAndConditions[sectionHeader].text) && // Text immediately under section header
            <>
              <h2 className={clsx([`${sectionHeader}-subheader`, classes.subHeader])}>{sectionHeader.replace(/_/g, ' ')}</h2>
              <body className={clsx([`${sectionHeader}-body`, classes.text])}>{termsAndConditions[sectionHeader].text}</body>
            </>
          }
          {!isUndefined(termsAndConditions[sectionHeader].children) && // If this section has a list, starts here
            <RecursiveList tokens={termsAndConditions[sectionHeader].children} level={0}/>
          }
        </>
      );
    })}
    <ContactInfo classes={classes} data={facilityData} />
  </>
);

/**
 * All of the types a list can have. Each layer
 * of indentation should be a different type
 * @type {string[]}
 */
const listTypes = ["1", "a", "i", "A", "I"];

/**
 * Process a list in json token form and change the type of
 * the list based on the level of indentation. ie 1 --> a --> i. Cycles through all options
 * @param tokens: starting array of object { text: "<string>", value: [<object>]}
 * @param level: which level of the tree you are on, used for list type
 * @returns {JSX.Element} Returns nested ordered list element
 * @constructor
 */
const RecursiveList = ({ tokens, level }) => (
  <ol type={listTypes[level % listTypes.length]}>
    {tokens.map((token, index) => (
      <li key={`${level}-${index}`}>{token.text}
        {!isUndefined(token.children) && // Go a level of indentation deeper
          <RecursiveList tokens={token.children} level={level+1}/>
        }
      </li>
    ))}
  </ol>
);

/**
 * Simple section for facility specific contact info
 * @param classes: passing down TermsAndConditions components to not reuse the hook everywhere
 * @param data: 
 * @returns {JSX.Element}
 * @constructor
 */
const ContactInfo = ({ classes, data }) => (
  <>
    <h3 className={clsx(["contactinfo-subheader", classes.subHeader])}>QUESTIONS</h3>
    <body className={clsx(["contactinfo-text", classes.text])}>If you have any questions concerning our return policy, please contact us at:</body>
    <ul className={clsx(["contactinfo-list", classes.contactInfo])}>
      <li key="name">{data?.name}</li>
      <li key="address1">{`${data?.address?.addressLine1 ?? ""}`}</li>
      <li key="address2">{`${data?.address?.city ?? ""}, ${data?.address?.state ?? ""}. ${data?.address?.postalCode ?? ""}`}</li>
      <li key="phone">{data?.phone}</li>
      <li key="email">{data?.email}</li>
    </ul>
  </>
);

export const termsFunctions = {
  TermsContent,
  RecursiveList,
  ContactInfo,
};