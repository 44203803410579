import React from "react";
import {useStyles} from "./styles";
import {Button} from "@material-ui/core";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import MobilePaymentService from "../../services/MobilePaymentService";
import apiClient from "../../auth/apiClient";
import {useEnqueueSnackbar} from "../../hooks/useEnqueueSnackbar";
import {isEmpty} from "lodash";
import clsx from "clsx";
import MuiPhoneNumber from "material-ui-phone-number";
import {TextField} from "formik-material-ui";
import { useFlags } from "launchdarkly-react-client-sdk";

const mobilePayService = new MobilePaymentService(apiClient);
const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/

export const sendReceiptSchema = Yup.object().shape({
    phone: Yup.string().matches(phoneRegex, "Phone number is not valid")
});

const TextReceiptLinkForm = ({ticketId}) => {
    const classes = useStyles();
    const enqueueSnackbar = useEnqueueSnackbar();
    const { mobilePaySmsReceipt } = useFlags();
    
    const sendReceipt = async (values) => {
        if (!values.phone || isEmpty(values.phone)) {
            enqueueSnackbar("A phone number must be provided.", {
                variant: "warning",
                tag: "badPhone",
            });
            return;
        }

        try {
            await mobilePayService.sendSMSReceipt({TicketID: ticketId, To: values.phone});
            enqueueSnackbar("Successfully sent receipt", {variant: "success"});
        } catch {
            enqueueSnackbar("Failed to send SMS message", {variant: "error", tag: "failToSendPhone"});
        }
    };
    
    return (
            <Formik
                initialValues={{
                    phone: "",
                }}
                validationSchema={sendReceiptSchema}
                onSubmit={async (values) => {
                    await sendReceipt(values);
                }}
            >
                {({submitForm, setFieldValue}) => (
                        <Form className={clsx(classes.flexCenter, classes.flexColumn, classes.spacing, "phone-receipt-form")}>
                            {mobilePaySmsReceipt && (
                                <>
                                <Field
                                    validateOnBlur
                                    validateOnChange
                                    name="primaryPhone2"
                                    data-id={"sms-textfield"}
                                    component={TextField}
                                    render={({field, form}) => (
                                        <MuiPhoneNumber
                                            className={clsx(classes.receiptPhoneInput, "receipt-phone-field")}
                                            defaultCountry={"us"}
                                            name="phone"
                                            onlyCountries={['us']}
                                            onChange={e => setFieldValue("phone", e)}
                                            label="Phone number"
                                            data-id="receipt-phone"
                                            variant="outlined"
                                            disabled={false}
                                            value={field.value}
                                            error={Boolean(
                                                form.errors.phone && form.touched.phone
                                            )}
                                            helperText={form.errors.phone}
                                        />
                                    )}
                                />
                                <Button
                                variant="contained"
                                className={clsx(classes.receiptButton, "send-receipt-button")}
                                color="primary"
                                aria-label={"send-receipt-button"}
                                title="Phone Receipt"
                                onClick={submitForm}
                                >
                                Send Receipt
                                </Button>
                                </>
                            )}
                        </Form>
                )}
            </Formik>
    );
};

export default TextReceiptLinkForm;
