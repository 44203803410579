import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

  headerContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "0 auto 1.5em auto",
  },

  codeReader: {
    display: "flex",
    justifyContent: "center",
    
    "& > div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flexGrow: "0"
    },
  },

  toggleButtonRoot: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    color: "white !important",
    backgroundColor: `${theme.palette.primary.main} !important`,
  },

  toggleButtonSelected: {
    backgroundColor: `${theme.palette.secondary.dark} !important`,
  },

  footerContainer: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1)
  }
}));