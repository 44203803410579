import React, { useState, useEffect, useCallback } from "react";
import { CircularProgress, Paper, Grid, Button, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.cjs.js'

import { useStyles } from "./styles";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";

import apiClient from "../../auth/apiClient";
import CredentialService from "../../services/CredentialService";
import MobilePaymentService from "../../services/MobilePaymentService";

import TicketDetails from "./TicketDetails";
import TicketLineItems from "./TicketLineItems";
import HistoryTimeline from "./HistoryTimeline";
import TermsAndConditions from "../Modals/TermsAndConditions";
import PayNowButton from "./PayNowButton";
import GenericError from "./GenericError";
import ValidUntilWarning from "./ValidUntilWarning";
import EmailRecieptForm from "./EmailRecieptForm";
import TotalDueCard from "./TotalDueCard";
import TextReceiptLinkForm from "./TextReceiptLinkForm";
import { ScanDiscounts } from "./Discounts/ScanDiscounts.index";
import moment from "moment";

const mobilePaymentService = new MobilePaymentService(apiClient);
const credentialService = new CredentialService(apiClient);

const MobilePayment = ({ ticketId, result, deleteQueryParam }) => {
  const classes = useStyles();

  // base UI state
  const [enabled, setEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPayNowButton, setShowPayNowButton] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  // pay now, UI state
  const [processorLoading, setProcessorLoading] = useState(false);
  // ticket state
  const [reloadTicket, setReloadTicket] = useState(false);
  const [currentTicket, setCurrentTicket] = useState(null);
  // discounts/validations state
  const [scanDiscountsOpen, setScanDiscountsOpen] = useState(false);
  const [claimedValidations, setClaimedValidations] = useState([]);
  // user idle state
  const timeout_scanDiscounts = 120_000; // 2 minute timeout when scanning discounts
  const timeout_session = 60_000; // 1 minute timeout everwhere else in mobile pay
  const [sessionTimeout, setSessionTimeout] = useState(timeout_session);

  const { mobilePrintedValidation } = useFlags();
  const enqueueSnackbar = useEnqueueSnackbar();
  const toastError = useCallback((msg, tag) => {
    enqueueSnackbar(msg, {
      variant: "error",
      tag
    })
  }, []);

  useEffect(() => {
    try {
      let queryParams = new URLSearchParams(window.location.search);
      if (queryParams.has("message")) {
        const errM = queryParams.get("message");
        if (errM == 'fail') {
          toastError('Transaction Declined');
        } else if (errM == "cancel") {
          updatePaymentStatusOfTicket();
        }
      }
      return;
    } catch (error) {
      console.error("Error getting query strings");
    }
  }, []);

  const history = useHistory();
  const navigateToScanAgain = useCallback(() => {
    history.replace("/mobilepay/scanagain");
  }, [history]);

  const handleScanDiscountsOpened = () => {
    setSessionTimeout(timeout_scanDiscounts);
    setScanDiscountsOpen(true);
  }

  const handleScanDiscountsClosed = () => {
    setSessionTimeout(timeout_session);
    setScanDiscountsOpen(false);
  }

  const handleProcessorLoading = () => {
    setProcessorLoading(true);
  }

  const handleProcessorLoaded = () => {
    setProcessorLoading(false);
  }

  const onIdle = useCallback(async () => {    
    if (!_.isEmpty(claimedValidations)) {
      var validationIds = claimedValidations.map((validation) => validation.validationsInventoryID);
      await credentialService.unapplyValidations(validationIds, currentTicket?.parkingTransactionID);
    }
    navigateToScanAgain()
  }, [claimedValidations, currentTicket, navigateToScanAgain]);

  useIdleTimer({
    onIdle,
    timeout: sessionTimeout,
    throttle: 500
  });

  const tryCloseTicket = useCallback(async () => {
    try {
      await mobilePaymentService.closeOut(ticketId);
      setReloadTicket(true);
    } catch (error) {
      var msg = error.response.status === 409 && !_.isEmpty(error.response?.data) 
        ? error.response.data
        : "Error closing ticket. See facility attendent.";
        toastError(msg);
    }
  }, [ticketId, toastError]);


  const updatePaymentStatusOfTicket = useCallback(async () => {
    try {
      await mobilePaymentService.updatePaymentStatus(ticketId);
    } catch (error) {
      console.error("Unable to update payment Status");
    }
  }, [ticketId]);

  const getTicketData = useCallback(async () => {
    try {
      setIsLoading(true);
      var response = await mobilePaymentService.getTicketDetails(ticketId);
      const ticket = response.data;
      setCurrentTicket(ticket);
      setEnabled(ticket.mobilePayEnabled);

      if (
          ticket.mobilePayEnabled &&
          ticket.remainingBalance === 0 &&
          ticket.totalDue > 0 &&
          ticket.status.toLowerCase() !== "exit" &&
          !ticket.zeroFeeDueToGraceTime &&
          !ticket.zeroFeeDueToLagTime) {
            await tryCloseTicket();
      }      
    } catch (error) {
      setErrorMessage(["Error retrieving ticket.", "Please try re-scanning with phone"]);
    } finally {
      setIsLoading(false);
    }
  }, [ticketId, tryCloseTicket]);

  const postPayment = useCallback(async () => {
    try {
      await mobilePaymentService.postProcessPayment(ticketId, result);
      if (deleteQueryParam) {
        deleteQueryParam();
      }
      setReloadTicket(true);
    } catch (error) {
      toastError("Unable to store payment", "unableToStorePayment");
      setErrorMessage([
        "Your payment was processed but an unexpected error occurred!",
        "Try refreshing this page. If that was unsuccessful, please see the local facility for assistance.",
      ]);
    }
  }, [ticketId, result, deleteQueryParam, toastError]);

  const onValidationScanned = useCallback(async (validation) => {
  try {
    await credentialService.tryApplyValidation(
      currentTicket.facilityID,
      validation.validationsInventoryID,
      currentTicket.parkingTransactionID);
  } catch(error) {
    const errorMsg = error.response?.data;
    if (error.response?.status === 409 && !_.isEmpty(errorMsg)) {
      toastError(errorMsg);
    } else {
      toastError("Unexpected error applying discount");
    }
    return; // on error don't close scan discounts
  }
    setClaimedValidations((prev) => [...prev, validation]);
    setReloadTicket(true);
    handleScanDiscountsClosed();
  }, [currentTicket, toastError]);

  useEffect(() => {
    if (!_.isEmpty(result) && !_.isEmpty(ticketId)) {
      postPayment();
    }
  }, [result, ticketId, postPayment])

  useEffect(() => {
    if (_.isEmpty(ticketId) 
      // ticket already exists, and we are not reloading? return
      || (!_.isNil(currentTicket) && !reloadTicket)) {
      return;
    }
    getTicketData();
    if (reloadTicket) {
      setReloadTicket(false);
    }
  }, [ticketId, currentTicket, reloadTicket, getTicketData]);

  useEffect(() => {
    const showPayNow = (enabled && currentTicket?.remainingBalance > 0);
    setShowPayNowButton(showPayNow);

    const showEmail = (currentTicket && enabled && !showPayNow) 
      && (currentTicket.remainingBalance <= 0 && currentTicket.transactions?.length > 0);
    setShowEmailForm(showEmail);
  }, [enabled, currentTicket]);

  return errorMessage 
    ? <GenericError message={errorMessage}></GenericError>
    : isLoading 
      ? <CircularProgress size={60} />
      : currentTicket && (
        scanDiscountsOpen 
          ? <ScanDiscounts 
            onClose={handleScanDiscountsClosed}
            entityID={currentTicket.facilityID}
            entryTime={currentTicket.enterTime}
            onValidRead={onValidationScanned}/>
          : <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper elevation={4} className={clsx(classes.paperContainerLarge)}>
                  <TicketDetails
                    ticketId={ticketId}
                    startTime={currentTicket.enterTime}
                    endTime={currentTicket.exitTime}
                  ></TicketDetails>
                  {showPayNowButton && (
                    <TicketLineItems
                      fees={currentTicket?.fees}
                      validations={currentTicket?.validations}
                    ></TicketLineItems>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <TotalDueCard remainingBalance={currentTicket.remainingBalance}></TotalDueCard>
              </Grid>
              {currentTicket?.transactions && currentTicket?.transactions.length > 0 && (
                <Grid item xs={12}>
                  <HistoryTimeline payments={currentTicket?.transactions}></HistoryTimeline>
                </Grid>
              )}
              <ValidUntilWarning rateValidUntil={currentTicket?.rateValidUntil} />
              {mobilePrintedValidation && showPayNowButton && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    data-testid="scan-discounts-btn"
                    onClick={handleScanDiscountsOpened}
                    disabled={processorLoading}
                  >
                    <Typography variant="h6">
                      Scan Discounts
                    </Typography>
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <>
                  {showPayNowButton && (
                    <PayNowButton
                      ticketId={ticketId}
                      balanceDue={currentTicket.remainingBalance}
                      mobilePaySessionID={currentTicket.mobilePaySessionID}
                      onProcessorLoading={handleProcessorLoading}
                      onProcessorLoaded={handleProcessorLoaded}/>
                  )}
                  {showEmailForm &&
                    <>
                      <EmailRecieptForm ticketId={ticketId}></EmailRecieptForm>
                      <TextReceiptLinkForm ticketId={ticketId} />
                    </>
                  }
                </>
              </Grid>

              <Grid item xs={12} className={clsx(classes.flexCenter)}>
                <div className={clsx(classes.termsAndConditions, "terms-and-conditions")}>
                  <TermsAndConditions ticketId={ticketId} />
                </div>
              </Grid>
            </Grid>
      )
};

export default MobilePayment;
