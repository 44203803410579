import React from "react";
import clsx from "clsx";

const ScanAgain = () => {
  const message = "Please re-scan with phone to pay";
  return (
    <div className={clsx("pay-now-button")} data-message={message}>
      {message}
    </div>
  );
};

export default ScanAgain;
