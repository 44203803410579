import React from "react";
import { useStyles } from "./styles";
import clsx from "clsx";
import { Typography } from "@material-ui/core";

const GenericError = ({ message, headerMessage }) => {
  const classes = useStyles();
  let messageList = [];
  if (Array.isArray(message)) {
    messageList = message;
  } else {
    messageList.push(message);
  }

  return (
    <div className={clsx(classes.errorContainer, "mobile-payment-error")}>
      <Typography variant="h6" gutterBottom component="div">
        {headerMessage ?? "An error has occured."}
      </Typography>
      <div className={clsx("error-message-list")}>
        {messageList.map((m, index) => (
          <div key={index} data-index={index} data-value={m}>
            {m}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GenericError;
