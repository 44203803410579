import React, { useState } from "react";
import { useStyles } from "./styles";
import clsx from "clsx";
import { Button } from "@material-ui/core";
import apiClient from "../../auth/apiClient";
import MobilePaymentService from "../../services/MobilePaymentService";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import { CircularProgress } from "@material-ui/core";
import AppConfig from "AppConfig";
import _ from "lodash";
import PlanetSelfSubmitForm from "../Forms/PlanetSelfSubmitForm";
import {PAYMENT_GATEWAY} from "../../constants/index";
const mobilePayService = new MobilePaymentService(apiClient);

const PayNowButton = ({ ticketId, balanceDue, mobilePaySessionID, onProcessorLoading, onProcessorLoaded }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const enqueueSnackbar = useEnqueueSnackbar();
  const currentLocation = window.location;
  const [tokenRequestResponse, setTokenRequestResponse] = React.useState({});
  const [hostBase, setHostBase] = useState('');
  const port = window.location.port ? ":" + window.location.port : "";

  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      tag: "payNowButtonError",
    });
  };

  const isValidHttpUrl = (val) => {
    let url;
    try {
      url = new URL(val);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };

  const initiateLoading = () => {
    setLoading(true);
    if (_.isFunction(onProcessorLoading)) {
      onProcessorLoading();
    }
  }

  const loadingComplete = () => {
    setLoading(false);
    if (_.isFunction(onProcessorLoaded)) {
      onProcessorLoaded();
    }
  }

  const getRedirectUri = async () => {
    try {
      initiateLoading();
      
      let response = await mobilePayService.postPaymentRequest(
        ticketId,
        `${currentLocation.protocol}//${currentLocation.hostname}${port}`,
        balanceDue,
        `${AppConfig.externalServices.baseURL}`,
        mobilePaySessionID
      );

      if (response.data?.gateway === PAYMENT_GATEWAY.WINDCAVE) {
        let redirectUri = response.data?.redirectUri;
        if (isValidHttpUrl(redirectUri)) {
          window.location.replace(redirectUri);
        } else {
          showError(`Unable to navigate to payment page due to invalid url: ${redirectUri}`);
          return;
        }
      } else if (response.data?.gateway === PAYMENT_GATEWAY.PLANET) {
        setHostBase(`${currentLocation.protocol}//${currentLocation.hostname}${port}/mobilepay/${ticketId}?message=cancel`);
        setTokenRequestResponse(response.data);
      }

    } catch (error) {
      if (error.response.status == 400) {
        showError("There is already a payment in progress for this ticket");
      } else {
        showError("Unable to negotiate payment processing");
      }
    } finally {
      loadingComplete();
    }
  };

  return (
    <>
    <Button
      variant="contained"
      color="primary"
      className={clsx(classes.payNowButton, "pay-now-button")}
      onClick={getRedirectUri}
      disabled={loading}
    >
      <div>
        Pay Now
        {loading && <CircularProgress color="secondary" size={12} style={{ marginLeft: "8px" }} />}
      </div>
    </Button>
    {/* Adding form for redirection to planet payment page once user clicks on ADD button */}
    {tokenRequestResponse.gateway === PAYMENT_GATEWAY.PLANET &&  
      (
        <PlanetSelfSubmitForm 
          planetRequestResponse={tokenRequestResponse}
          hostBase={hostBase}
        />
    )}
    </>
  );
};

export default PayNowButton;