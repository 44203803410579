import React, { useState, useEffect } from "react";
import Currency from "../Currency";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Payment, AttachMoney, MonetizationOn } from "@material-ui/icons";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@material-ui/lab";
import moment from "moment-timezone";
import { useStyles } from "./styles";
import clsx from "clsx";
import { PAYMENTS_TENDERED_ENTRY_TYPES } from "../../constants";
import _ from "lodash";

const HistoryTimeline = ({ payments }) => {
  const classes = useStyles();
  const [timelineItems, setTimelineItems] = useState([]);

  useEffect(() => {
    if (payments && payments.length > 0) {
      setTimelineItems(
        payments.map((x) => ({
          date: x.time,
          formattedTime: formatDt(x.time),
          icon: formatTypeToIcon(x.typeId),
          description: x.description,
          validationOfferName: x.validationOfferName,
          showColor: showColor(x.typeId),
          amount: x.amount,
        }))
      );
    }
  }, [payments]);

  const formatDt = (utcDt) => {
    var localDt = moment.utc(utcDt).local();
    var now = moment().local();

    var yearsDiff = now.diff(localDt, "years");
    var daysDif = now.diff(localDt, "days");
    var diffInHours = now.diff(localDt, "hours");

    var format = "MMM Do, h:mm a";
    if (yearsDiff > 0) {
      format = "MMM Do YYYY, h:mm a";
    } else if (daysDif < 6) {
      format = "ddd, h:mm a";
    } else if (diffInHours < 12) {
      format = "LT";
    }

    return localDt.format(format);
  };

  const formatTypeToIcon = (typeId) => {
    switch (typeId) {
      case PAYMENTS_TENDERED_ENTRY_TYPES.CreditCardApplied: {
        return <Payment />;
      }
      case PAYMENTS_TENDERED_ENTRY_TYPES.CashApplied:
      case PAYMENTS_TENDERED_ENTRY_TYPES.ValidationDiscount: {
        return <AttachMoney />;
      }
      case PAYMENTS_TENDERED_ENTRY_TYPES.ValidationSurchage:
      case PAYMENTS_TENDERED_ENTRY_TYPES.BaseFee:
      case PAYMENTS_TENDERED_ENTRY_TYPES.OverageFee:
      case PAYMENTS_TENDERED_ENTRY_TYPES.LateFee:
      case PAYMENTS_TENDERED_ENTRY_TYPES.RepayFee:
      case PAYMENTS_TENDERED_ENTRY_TYPES.CashBack:
      case PAYMENTS_TENDERED_ENTRY_TYPES.ConvenienceFee:
      case PAYMENTS_TENDERED_ENTRY_TYPES.Tax:
      case PAYMENTS_TENDERED_ENTRY_TYPES.FlexParkingFee: {
        return <MonetizationOn />;
      }
      default: {
        return "";
      }
    }
  };

  const showColor = (typeId) => {
    switch (typeId) {
      case PAYMENTS_TENDERED_ENTRY_TYPES.CashApplied:
      case PAYMENTS_TENDERED_ENTRY_TYPES.CreditCardApplied: {
        return true;
      }
      default: {
        return false;
      }
    }
  };

  return (
    <>
      <Accordion
        defaultExpanded={true}
        elevation={4}
        className="history-panel ui"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            className={clsx(classes.accordingLabel)}
          >
            History
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Timeline
            style={{
              padding: "6px 4px",
            }}
          >
            {timelineItems.map((val, index) => (
              <TimelineItem
                key={index}
                data-index={index}
                data-amount={val.amount}
                data-description={val.description}
                data-date={val.date}
                data-formatted-time={val.formattedTime}
                data-show-color={val.showColor}
              >
                <TimelineOppositeContent color="text.secondary">
                  {
                    <>
                      <div className={clsx(classes.timelineDetails)}>
                        <div>{val.icon}</div>
                        <div>
                          <div> {val.formattedTime}</div>
                          <div
                            className={clsx(classes.timelineItemDescription)}
                          >
                            {val.description}
                            {!_.isNil(val.validationOfferName) && (
                              <span><br/>
                                {val.validationOfferName}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </TimelineOppositeContent>
                <TimelineSeparator>
                  {val.showColor ? (
                    <TimelineDot
                      color="inherit"
                      className={clsx(classes.styledTimelineDot)}
                    />
                  ) : (
                    <TimelineDot />
                  )}
                  {index != payments.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Currency value={val.amount}></Currency>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default HistoryTimeline;
