import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, Divider } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./styles";
import Title from "../Title";
import * as Yup from "yup";
import AddEditLogosAndBanners from "./AddEditLogosAndBanners";
import ListLogosAndbanners from "./ListLogosAndBanners";
import MediaService from "../../services/MediaService";
import apiClient from "../../auth/apiClient";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";

export const LogosAndBannersSchema = Yup.object().shape({
  logoname: Yup.string().required("Logo name is required"),
  logoconfiguration: Yup.number()
    .typeError("Must be a positive number")
    .integer("Must be a whole number")
    .min(0, "Must be between 0 to 60 seconds")
    .max(60, "Must be between 0 to 60 seconds"),
});

const LogosAndBanners = ({ entityID, onClose, parentEntityID, entityType }) => {
  const mediaService = new MediaService(apiClient);
  const enqueueSnackbar = useEnqueueSnackbar();
  const classes = useStyles();
  const { showDialog } = useConfirmationDialog();
  const [logoAndBannerState, setLogoAndBannerState] = useState({
    title: "Logos and Banners",
    isAddEditLogo: false,
  });
  const [logosAndBannersList, setLogosAndBannersList] = useState({
    collection: [],
    totalCount: 0,
  });
  const [selectedLogo, setSelectedLogo] = useState({});

  const handleAddClick = () => {
    setLogoAndBannerState({
      title: "Add Logos and Banners",
      isAddEditLogo: true,
    });
    setSelectedLogo({});
  };

  const handleEditLogo = async (logoConfiguration) => {
    try {
      const result = await mediaService.getMediaLogo(logoConfiguration.mediaId);
      const updatedLogo = { ...logoConfiguration, image: result.data };
      setSelectedLogo(updatedLogo);
      setLogoAndBannerState({
        title: "Edit Logos and Banners",
        isAddEditLogo: true,
      });
    } catch (err) {
      enqueueSnackbar("An error occurred while fetching logos image!", {
        variant: "error",
        tag: "FailedToFetchLogosAndBanners",
      });
    }
  };

  const handleDeleteLogo = async (mediaId) => {
    const response = await showDialog({
      title: `Delete Logo and Banner`,
      message: "Are you sure you want to delete this banner?",
      buttons: [
        { name: "Yes", color: "primary" },
        { name: "No", color: "secondary" },
      ],
    });
    if (response === "No") return;
    try {
      await mediaService.deleteMedia(mediaId);
      fetchLogosAndBanners(entityID);
    } catch (err) {
      enqueueSnackbar("An error occurred while deleting logo", {
        variant: "error",
        tag: "FailedToFetchLogosAndBanners",
      });
      return;
    }
  };

  const fetchLogosAndBanners = async (entityID, limit, page) => {
    try {
      const result = await mediaService.getMediaList(
        entityID,
        entityType.toLowerCase() === "device" ? parentEntityID : null,
        limit,
        page
      );
      setLogosAndBannersList(result.data);
      setSelectedLogo({});
    } catch (err) {
      enqueueSnackbar("An error occurred while fetching logos and banners!", {
        variant: "error",
        tag: "FailedToFetchLogosAndBanners",
      });
    }
  };

  useEffect(() => {
    fetchLogosAndBanners(entityID);
  }, [entityID]);

  const handleLogoBannerSave = async (logoConfiguration, logo) => {
    let media = {
      logoName: logoConfiguration.logoname,
      timeout: logoConfiguration.logoconfiguration,
      encodedImage: logo.logoAndBannerImage.split(",")[1],
      imageExtension: logo.imageExtension,
    };
    if (entityType.toLowerCase() === "device") {
      media = { ...media, entityId: parentEntityID, deviceId: entityID };
    } else {
      media = { ...media, entityId: entityID };
    }

    try {
      !selectedLogo.mediaId
        ? await mediaService.createMedia(media)
        : await mediaService.updateMedia({
            ...media,
            mediaid: selectedLogo.mediaId,
          });
      fetchLogosAndBanners(entityID);
      setLogoAndBannerState({
        title: "Logos and Banners",
        isAddEditLogo: false,
      });
    } catch (err) {
      enqueueSnackbar("An error occurred while saving logos and banners!", {
        variant: "error",
        tag: "FailedToSaveLogosAndBanners",
      });
    }
  };

  const handlePageChange = (e, page) => {
    fetchLogosAndBanners(entityID, 10, page - 1);
  };

  const handleAddEditDrawerClose = () => {
    setLogoAndBannerState({
      title: "Logos and Banners",
      isAddEditLogo: false,
    });
  }

  return (
    <>
      <Grid container className={clsx([classes.drawerContainer, logoAndBannerState.isAddEditLogo ? "add-edit-logo-banners-container" : 'list-logos-banners-container'])} spacing={2}>
        <Grid
          item
          xs={logoAndBannerState.isAddEditLogo ? 12 : 9}
          lg={logoAndBannerState.isAddEditLogo ? 12 : 10}
        >
          <Typography className={clsx([classes.offerTitle])}>
            <Title>{logoAndBannerState.title}</Title>
          </Typography>
        </Grid>
        {!logoAndBannerState.isAddEditLogo && (
          <Grid
            container
            item
            xs={3}
            lg={2}
            justify="flex-end"
            alignItems="center"
          >
            <Button
              color="primary"
              name="submit"
              variant="contained"
              onClick={handleAddClick}
            >
              Add
            </Button>
          </Grid>
        )}
      </Grid>
      <Divider />
      {logoAndBannerState.isAddEditLogo && (
        <AddEditLogosAndBanners
          entityID=""
          onClose={handleAddEditDrawerClose}
          selectedLogo={selectedLogo}
          saveImage={handleLogoBannerSave}
        />
      )}
      {!logoAndBannerState.isAddEditLogo && (
        <ListLogosAndbanners
          entityID={entityID}
          onClose={onClose}
          logosAndBanners={logosAndBannersList}
          editLogo={handleEditLogo}
          deleteLogo={handleDeleteLogo}
          listLogoBannerPageChange={handlePageChange}
        />
      )}
    </>
  );
};

LogosAndBanners.defaultProps = {
  entityID: "",
  onClose: () => {},
  parentEntityID: "",
  entityType: "",
};

LogosAndBanners.propTypes = {
  entityID: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  parentEntityID: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
};

export default LogosAndBanners;
