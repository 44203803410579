import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  FormControl,
  Divider,
  Tooltip,
  Box,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "../styles";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const LogosAndBannersSchema = Yup.object().shape({
  logoname: Yup.string()
    .required("Logo name is required")
    .max(25, "Maximum of 25 characters"),
  logoconfiguration: Yup.number()
    .typeError("Must be a positive number")
    .integer("Must be a whole number")
    .min(1, "Must be between 1 to 60 seconds")
    .max(60, "Must be between 1 to 60 seconds"),
});

const AddEditLogosAndBanners = ({ onClose, selectedLogo, saveImage }) => {
  const fileTypesSupported = ["image/png", "image/svg+xml"];
  const classes = useStyles();
  const inputElement = useRef(null);
  const [logoAndBannerData, setLogoAndBannerState] = useState({
    logoAndBannerImage: null,
    imageExtension: null,
    errorToDisplay: "",
  });

  useEffect(() => {
    const logo = selectedLogo.image;
    if (logo) {
      setLogoAndBannerState({
        logoAndBannerImage: `data:${
          logo.contentType ? logo.contentType : "image/png"
        };base64,${logo.encodedImage}`,
        imageExtension: convertFileType(logo.contentType),
        errorToDisplay: "",
      });
    }
  }, [selectedLogo.image]);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    defaultValues: {
      logoname: selectedLogo.logoName ? selectedLogo.logoName : "",
      logoconfiguration: selectedLogo.timeout ? selectedLogo.timeout : 1,
    },
    resolver: yupResolver(LogosAndBannersSchema),
    mode: "onChange",
  });

  const handleFileSelectedChange = (event) => {
    if (event.target.files.length > 0) {
      var file = event.target.files[0];
      var validType = fileTypesSupported.includes(event.target.files[0].type);
      var validSize =
        event.target.files[0].size > 0 && event.target.files[0].size < 1048576;

      if (!validType || !validSize) {
        if (!validSize) {
          setLogoAndBannerState({
            logoAndBannerImage: null,
            errorToDisplay: "Invalid file size",
          });
        } else {
          setLogoAndBannerState({
            logoAndBannerImage: null,
            errorToDisplay: "Invalid file type",
          });
        }
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e) {
        setLogoAndBannerState({
          logoAndBannerImage: e.target.result,
          imageExtension: convertFileType(file.type),
          errorToDisplay: "",
        });
      };
    }
  };

  const convertFileType = (type) => {
    switch (type) {
      case "image/png":
        return "png";
      case "image/svg+xml":
        return "svg";
      default:
        return null;
    }
  };

  const onSubmit = async(data) => {
    await saveImage(data, logoAndBannerData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        className={clsx([classes.drawerContainer])}
        spacing={2}
        data-testid="add-edit-logo-form"
      >
        <Grid item xs={12}>
          <Controller
            name="logoname"
            control={control}
            render={({ field }) => (
              <FormControl
                fullWidth
                formlabel="Name"
                role="lpr-ticketless-exception-feedelay"
              >
                <TextField
                  {...field}
                  data-testid="logo-name"
                  id="logoname"
                  label="Name"
                  variant="outlined"
                  error={!!errors.logoname}
                  helperText={errors?.logoname && errors.logoname?.message}
                  required
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="logoconfiguration"
            control={control}
            render={({ field }) => (
              <FormControl
                fullWidth
                formlabel="Configuration"
                role="lpr-ticketless-exception-feedelay"
              >
                <Tooltip
                  title="The amount of time this logo is displayed on the device if there are multiple."
                  arrow
                >
                  <TextField
                    {...field}
                    id="logoconfiguration"
                    data-testid="logo-display-time"
                    label="Display Time(in seconds)"
                    variant="outlined"
                    error={!!errors.logoconfiguration}
                    helperText={
                      errors?.logoconfiguration &&
                      errors.logoconfiguration?.message
                    }
                    required
                  />
                </Tooltip>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Tooltip
            arrow
            title="The recommended image dimensions are 900 pixels wide, 200 pixels tall. Other dimensions can be used, but the image may be distorted when displayed on the device. The file format must be a .png or .svg. The file must be less than 1mb in size."
          >
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => inputElement.current.click()}
            >
              Choose banner image
            </Button>
          </Tooltip>
        </Grid>
        <input
          type="file"
          name="logoimagefile"
          id="logoimagefile"
          data-testid="logoimagefile"
          onChange={handleFileSelectedChange}
          ref={inputElement}
          accept="image/png, image/svg+xml"
          style={{ display: "none" }}
        />
        {logoAndBannerData.logoAndBannerImage ? (
          <Grid item xs={12}>
            <FormControl fullWidth FormLabel="Banner Image">
              <img
                className={clsx("poe-banner-image", classes.bannerImage)}
                src={logoAndBannerData.logoAndBannerImage}
                alt="Logo"
              />
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={12} md={12}>
            {logoAndBannerData.errorToDisplay && (
              <Typography
                className={clsx("image-error-message", classes.errorMessage)}
              >
                {logoAndBannerData.errorToDisplay}
              </Typography>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <Box className={classes.footerDivider}>
            <Divider />
          </Box>
        </Grid>
        <Grid item container justify="flex-end">
          <Box className={classes.footerButtons} mr={2} mb={2}>
            <Box mr={1}>
              <Button
                color="primary"
                name="submit"
                type="submit"
                variant="contained"
                disabled={!isValid || !logoAndBannerData.logoAndBannerImage || isSubmitting}
              >
                Save
              </Button>
            </Box>
            <Button name="close" variant="contained" onClick={onClose}>
              Close
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

AddEditLogosAndBanners.defaultProps = {
  entityID: "",
  onClose: () => {},
  logosAndBanners: [],
};

AddEditLogosAndBanners.propTypes = {
  entityID: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  logosAndBanners: PropTypes.array,
};

export default AddEditLogosAndBanners;
