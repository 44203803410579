import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/* Components */
import { Dialog, DialogContent } from "@material-ui/core";
import ValidationAccountUserForm from "../../Forms/ValidationAccountUser";

/* Hooks */
import useValidationAccountUserFunctions from "../../../hooks/ValidationAccounts/useValidationAccountUserFunctions";

export default function ValidationUserFormModal({
  identifier,
  onUserCreated,
  open,
  cancelled,
  validationAccountId
}) {
  const { resendInvitation } = useValidationAccountUserFunctions();

  return (
    <Dialog open={open} className={clsx(identifier)} data-testid={identifier}>
      <DialogContent>
        <ValidationAccountUserForm
          onCancel={cancelled}
          onSubmitComplete={onUserCreated}
          handleResend={resendInvitation}
          validationAccountId={validationAccountId}
        />
      </DialogContent>
    </Dialog>
  );
}

ValidationUserFormModal.defaultProps = {
  identifier: "validation-user-modal",
  onComplete: () => {},
  cancelled: () => {},
  open: false,
};

ValidationUserFormModal.propTypes = {
  identifier: PropTypes.string,
  onComplete: PropTypes.func,
  cancelled: PropTypes.func,
  open: PropTypes.bool,
};
