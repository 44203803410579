import React, { useCallback, useEffect } from "react"
import { Button, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";

import { useStyles } from "./ScanDiscounts.style";
import useCredentialScanner from "../../../hooks/useCredentialScanner";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import apiClient from "../../../auth/apiClient";
import MobilePaymentService from "../../../services/MobilePaymentService";

const mobilePaymentService = new MobilePaymentService(apiClient);

export const ScanDiscounts = ({ onClose, entityID, entryTime, onValidRead }) => {

  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();

  const errorToast = useCallback((msg) => {
    enqueueSnackbar(msg, { variant: "error" });
  }, []);

  const {
    Reader,
    credentialRead,
    forceScan,
  } = useCredentialScanner({
    readerStyle: classes,
    ticketsOnly: false,
    toggleButtonTextOn: "Stop Scanning",
    toggleButtonTextOff: "Scan for Discounts"
  });

  const verifyValidation = useCallback(async (credentialReference) => {
    try {
      const response = await mobilePaymentService.tryGetValidation(credentialReference, entityID, entryTime);
      const credential = response?.data;
      if (_.isNil(response) || response?.status !== 200
        || credential.credentialType.toLowerCase() !== "validation") {
        errorToast("Scanned discount is not valid");
      }

      return credential;
    } catch (error) {
      let errorMsg = "Error loading discount. Please try scanning the discount again.";
      if (!_.isEmpty(error.response.data?.errorMessage)) {
        const response = error.response.data;
        errorMsg = response.resultCode === "CredentialNotFound" // look for this to remove "credential" verbiage
          ? "Validation not found. Please try scanning the discount again."
          : response.errorMessage;
      }
      errorToast(errorMsg);
      return null;
    }  
  }, [entityID, entryTime, errorToast]);

  useEffect(() => {
    if (_.isNil(credentialRead)) {
      // ignore on first load, outside that should never be null
      return;
    }
    verifyValidation(credentialRead).then((validation) => {
      if (!_.isNil(validation)) {
        onValidRead(validation);
      }
    });
  }, [credentialRead, verifyValidation, onValidRead]);

  return (
    <Grid container onPaste={(e) => forceScan(e.clipboardData.getData("Text"))}>
      <Grid item xs={12} className={classes.headerContainer}>
        <Typography variant="h4">
          Scan Discounts
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Reader />
      </Grid>
      <Grid item xs={12} className={classes.footerContainer}>
        <Button
          className={clsx("close-scan-discounts")}
          onClick={onClose}
          variant="outlined">
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
}