import { AGREEMENT_TO_TERMS, PROHIBITED_ACTIVITIES, DISCLAIMER, INTELLECTUAL_PROPERTY_RIGHTS,
USER_REPRESENTATIONS, USER_GENERATED_CONTRIBUTIONS, CONTRIBUTION_LICENSE, SUBMISSIONS, SITE_MANAGEMENT,
PRIVACY_POLICY, TERM_AND_TERMINATION, MODIFICATIONS_AND_INTERRUPTIONS, GOVERNING_LAW, CORRECTIONS, 
LIMITATIONS_OF_LIABILITY, INDEMNIFICATION, USER_DATA, ELECTRONIC_COMMUNICATIONS_TRANSACTIONS_AND_SIGNATURES,
CALIFORNIA_USERS_AND_RESIDENTS, MISCELLANEOUS, CONTACT_US, PAYMENTS, RETURN_AND_REFUND_POLICY } from './termSections';

export const TERMS_AND_CONDITIONS = {
  lastUpdated: "September 1, 2020",
  AGREEMENT_TO_TERMS,
  INTELLECTUAL_PROPERTY_RIGHTS,
  USER_REPRESENTATIONS,
  PROHIBITED_ACTIVITIES,
  USER_GENERATED_CONTRIBUTIONS,
  CONTRIBUTION_LICENSE,
  SUBMISSIONS,
  SITE_MANAGEMENT,
  PRIVACY_POLICY,
  TERM_AND_TERMINATION,
  MODIFICATIONS_AND_INTERRUPTIONS,
  GOVERNING_LAW,
  CORRECTIONS,
  DISCLAIMER,
  LIMITATIONS_OF_LIABILITY,
  INDEMNIFICATION,
  USER_DATA,
  ELECTRONIC_COMMUNICATIONS_TRANSACTIONS_AND_SIGNATURES,
  CALIFORNIA_USERS_AND_RESIDENTS,
  MISCELLANEOUS,
  CONTACT_US,
  PAYMENTS,
  RETURN_AND_REFUND_POLICY,
};