/* eslint-disable no-multi-str */
// Each variable in this file corresponds to a section of the terms and conditions
// the name of each variable will be a section subheader
// the text of the first level with be a body element under the subheader
// the children is a possibly nested list since there are so many lists in the terms and conditions

export const AGREEMENT_TO_TERMS = {
  text: "These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”, “customer”) and Amano McGann (\"Amano McGann\", \“we\”, \“us\”, or \“our\”), concerning your access to and use of this website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). You agree that by accessing the Site, you have read, understood, and agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY. \n\n \
    Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of these Terms of Use, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Terms of Use to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Use by your continued use of the Site after the date such revised Terms of Use are posted. \n\n \
    The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable. \n\n \
    The Site is intended for users who are at least 18 years old. Persons under the age of 16 are not permitted to use or register for the Site.\n\n",
};

export const INTELLECTUAL_PROPERTY_RIGHTS = {
  text: "Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, international copyright laws, and international conventions. The Content and the Marks are provided on the Site “AS IS” for your information and personal use only. Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission. \n\n \
  Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content and the Marks.\n\n"
};

export const USER_REPRESENTATIONS = {
  text: "By using the Site, you represent and warrant that: (1) you have the legal capacity and you agree to comply with these Terms of Use; (2) you are not a minor in the jurisdiction in which you reside; (3) you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise; (4) you will not use the Site for any illegal or unauthorized purpose; and (5) your use of the Site will not violate any applicable law or regulation. \n\n \
  If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof)."
};

export const PROHIBITED_ACTIVITIES = {
  text: "You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us. \n\n \
    As a user of the Site, you agree not to:",
  children: [
    {
      text: "Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.",
    },
    {
      text: "Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.",
    },
    {
      text: "Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein.",
    },
    {
      text: "Use any information obtained from the Site in order to harass, abuse, or harm another person.",
    },
    {
      text: "Use the Site in a manner inconsistent with any applicable laws or regulations.",
    },
    {
      text: "Engage in unauthorized framing of or linking to the Site.",
    },
    {
      text: "Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site.",
    },
    {
      text: "Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.",
    },
    {
      text: "Delete the copyright or other proprietary rights notice from any Content",
    },
    {
      text: "Attempt to impersonate another user or person or use the username of another user.",
    },
    {
      text: "Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).",
    },
    {
      text: "Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site.",
    },
    {
      text: "Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.",
    },
    {
      text: "Use a buying agent or purchasing agent to make purchases on the Site.",
    },
    {
      text: "Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.",
    },
    {
      text: "Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any revenue-generating endeavor or commercial enterprise.",
    }
  ]
};

export const USER_GENERATED_CONTRIBUTIONS = {
  text: "The Site does not offer users to submit or post content."
};

export const CONTRIBUTION_LICENSE = {
  text: "You and the Site agree that we may access, store, process, and use any information and personal data that you provide following the terms of the Privacy Policy and your choices (including settings).\n\n \
  By submitting suggestions or other feedback regarding the Site, you agree that we can use and share such feedback for any purpose without compensation to you.\n\n \
  We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area on the Site. You are solely responsible for your Contributions to the Site and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions."
};

export const SUBMISSIONS = {
  text: "You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the Site (\"Submissions\") provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you. You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.",
};

export const SITE_MANAGEMENT = {
  text: "We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.\n\n",
};

export const PRIVACY_POLICY = {
  text: "Your privacy is important to us. It is Amano McGann policy to respect your privacy regarding any information we may collect from you across our website and other sites we own and operate.",
  children: [
    {
      text: "Information we collect",
    },
    {
      text: "Log data",
      children: [
        {
          text: "When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your computer’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details.",
        }
      ]
    },
    {
      text: "Personal Information",
      children: [
        {
          text: "We may ask for personal information, such as your:",
          children: [
            {
              text: "Name"
            },
            {
              text: "Email"
            },
            {
              text: "Phone/Mobile Number"
            },
            {
              text: "Payment Information"
            }
          ]
        }
      ]
    },
    {
      text: "Legal bases for processing"
    },
    {
      text: "We will process your personal information lawfully, fairly and in a transparent manner. We collect and process information about you only where we have legal bases for doing so."
    },
    {
      text: "These legal bases depend on the services you use and how you use them, meaning we collect and use your information only where:",
      children: [
        {
          text: "it’s necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract (for example, when we provide a service you request from us);",
        },
        {
          text: "you give us consent to do so for a specific purpose.",
        },
        {
          text: "we need to process your data to comply with a legal obligation.",
        }
      ]
    },
    {
      text: "Where you consent to our use of information about you for a specific purpose, you have the right to change your mind at any time (but this will not affect any processing that has already taken place)."
    },
    {
      text: "We don’t keep personal information for longer than is necessary. While we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification. That said, we advise that no method of electronic transmission or storage is 100% secure and cannot guarantee absolute data security. If necessary, we may retain your personal information for our compliance with a legal obligation or in order to protect your vital interests or the vital interests of another natural person."
    },
    {
      text: "Collection and use of information",
    },
    {
      text: "We may collect, hold, use and disclose information for the following purposes and personal information can be processed only in a manner that is compatible with these purposes:",
      children: [
        {
          text: "to enable you to access and use our website and associated applications."
        },
        {
          text: "to contact and communicate with you;"
        },
        {
          text: "for internal record keeping and administrative purposes;"
        },
        {
          text: "for analytics, and business development, including to operate and improve our website and associated applications."
        }
      ]
    },
    {
      text: "Disclosure of personal information to third parties",
    },
    {
      text: "We may disclose personal information to:",
      children: [
        {
          text: "third party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, Parking Service providers (limited to the operating party of parking services which you are parking), professional advisors and payment systems operators.",
        },
        {
          text: "courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights;",
        }
      ]
    },
    {
      text: "Your rights and controlling your personal information",
    },
    {
      text: "Choice and consent: By providing personal information to us, you consent to us collecting, holding, using and disclosing your personal information in accordance with this privacy policy. If you are under 16 years of age, you must have, and warrant to the extent permitted by law to us, that you have your parent or legal guardian’s permission to access and use the website and they (your parents or guardian) have consented to you providing us with your personal information. You do not have to provide personal information to us, however, if you do not, it may affect your use of this website or the products and/or services offered on or through it.",
    },
    {
      text: "Information from third parties: We do not receive personal information about you from any third party.",
    },
    {
      text: "Restrict: You may choose to restrict the collection or use of your personal information. If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details below. If you ask us to restrict or limit how we process your personal information, we will let you know how the restriction affects your use of our website or products and services.",
    },
    {
      text: "Access and data portability: You may request details of the personal information that we hold about you. You may request a copy of the personal information we hold about you. Where possible, we will provide this information in a easily readable machine format. You may request that we erase the personal information we hold about you at any time. You may also request that we transfer this personal information to another third party.",
    },
    {
      text: "Correction: If you believe that any information, we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us using the details below. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading or out of date.",
    },
    {
      text: "Notification of data breaches: We will comply with laws applicable to us in respect of any data breach.",
    },
    {
      text: "Complaints: If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.",
    },
    {
      text: "Cookies"
    },
    {
      text: "We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified. Please refer to our Cookie Policy for more information."
    },
    {
      text: "Business transfers",
    },
    {
      text: "If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we would include transactional data among the assets transferred to any parties who acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may continue to use your personal information according to this policy.",
    },
    {
      text: "Limits of our policy",
    },
    {
      text: "Our website may link to external sites that are not operated by us. I.e. Payment Gateways. Please be aware that we have no control over the content and policies of those sites and cannot accept responsibility or liability for their respective privacy practices.",
    },
    {
      text: "Changes to this policy",
    },
    {
      text: "At our discretion, we may change our privacy policy at any time to reflect current commercially acceptable practices. We will take reasonable steps to let users know about changes via our website. Your continued use of this site after any changes to this policy will be regarded as acceptance of our practices around privacy and personal information. 2. If we make a significant change to this privacy policy, for example changing a lawful basis on which we process your personal information, we will ask you to re-consent to the amended privacy policy.",
    }
  ]
};

export const TERM_AND_TERMINATION = {
  text: "These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.",
};

export const MODIFICATIONS_AND_INTERRUPTIONS = {
  text: "We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.\n\n \
  We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Site. Nothing in these Terms of Use will be construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases in connection therewith."
};

export const GOVERNING_LAW = {
  text: "These Terms of Use and your use of the Site are governed by and construed in accordance with the laws of the State of Minnesota applicable to agreements made and to be entirely performed within the State of Minnesota, without regard to its conflict of law principles.",
};

export const CORRECTIONS = {
  text: "There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.",
};

export const DISCLAIMER = {
  text: "",
  children: [
    {
      text: "Interpretation and Definitions",
      children: [
        {
          text: "Interpretation",
          children: [
            {
              text: "The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.",
            },
          ]
        },
        {
          text: "Definitions",
          children: [
            {
              text: "For the purposes of this Disclaimer:",
            },
            {
              text: "Company (referred to as either \"the Company\", \"We\", \"Us\" or \"Our\" in this Disclaimer) refers to Amano McGann, 2699 Patton Road Roseville, MN 55113 .",
            },
            {
              text: "Service refers to the Website. ",
            },
            {
              text: "You means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.",
            },
            {
              text: "Website refers to https://app.amano-one.com and https://www.amanomcgann.com/ and https://www.amano-one.com and CVPS.Solutions and http://CVPS.Solutions and http://ValetPlease.com",
            }
          ]
        }
      ]
    },
    {
      text: "Disclaimer",
      children: [
        {
          text: "The information contained on the Service is for general information purposes only.",
        },
        {
          text: "The Company assumes no responsibility for errors or omissions in the contents of the Service.",
        },
        {
          text: "In no event shall the Company be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.",
        },
        {
          text: "The Company does not warrant that the Service is free of viruses or other harmful components.",
        }
      ]
    },
    {
      text: "External Links Disclaimer",
      children: [
        {
          text: "The Service may contain links to external websites that are not provided or maintained by or in any way affiliated with the Company.",
        },
        {
          text: "Please note that the Company does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.",
        }
      ]
    },
    {
      text: "Errors and Omissions Disclaimer",
      children: [
        {
          text: "The information given by the Service is for general guidance on matters of interest only. Even if the Company takes every precaution to ensure that the content of the Service is both current and accurate, errors can occur. Plus, given the changing nature of laws, rules and regulations, there may be delays, omissions or inaccuracies in the information contained on the Service.",
        },
        {
          text: "The Company is not responsible for any errors or omissions, or for the results obtained from the use of this information.",
        }
      ]
    },
    {
      text: "Fair Use Disclaimer",
      children: [
        {
          text: "The Company may use copyrighted material which has not always been specifically authorized by the copyright owner. The Company is making such material available for criticism, comment, news reporting, teaching, scholarship, or research.",
        },
        {
          text: "The Company believes this constitutes a \"fair use\" of any such copyrighted material as provided for in section 107 of the United States Copyright law. c. If You wish to use copyrighted material from the Service for your own purposes that go beyond fair use, You must obtain permission from the copyright owner.",
        }
      ]
    },
    {
      text: "Views Expressed Disclaimer",
      children: [
        {
          text: "The Service may contain views and opinions which are those of the authors and do not necessarily reflect the official policy or position of any other author, agency, organization, employer or company, including the Company.",
        },
        {
          text: "Comments published by users are their sole responsibility and the users will take full responsibility, liability and blame for any libel or litigation that results from something written in or as a direct result of something written in a comment. The Company is not liable for any comment published by users and reserve the right to delete any comment for any reason whatsoever.",
        }
      ]
    },
    {
      text: "No Responsibility Disclaimer",
      children: [
        {
          text: "The information on the Service is provided with the understanding that the Company is not herein engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal or other competent advisers.",
        },
        {
          text: "In no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever arising out of or in connection with your access or use or inability to access or use the Service.",
        }
      ]
    },
    {
      text: "\"Use at Your Own Risk\" Disclaimer",
      children: [
        {
          text: "All information in the Service is provided \"as is\", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose.",
        },
        {
          text: "The Company will not be liable to You or anyone else for any decision made or action taken in reliance on the information given by the Service or for any consequential, special or similar damages, even if advised of the possibility of such damages.",
        }
      ]
    },
    {
      text: "Contact Us If you have any questions about this Disclaimer, You can contact Us: By email: marketing@amanomcgann.com or By visiting our website: https://www.amanomcgann.com/contact-us/",
    }
  ]
};

export const LIMITATIONS_OF_LIABILITY = {
  text: "IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.",
};

export const INDEMNIFICATION = {
  text: "You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) use of the Site; (2) breach of these Terms of Use; (3) any breach of your representations and warranties set forth in these Terms of Use; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the Site with whom you connected via the Site. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.",
};

export const USER_DATA = {
  text: "We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the Site, as well as data relating to your use of the Site. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Site. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.",
};

export const ELECTRONIC_COMMUNICATIONS_TRANSACTIONS_AND_SIGNATURES = {
  text: "Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.",
};

export const CALIFORNIA_USERS_AND_RESIDENTS = {
  text: "If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.",
};

export const MISCELLANEOUS = {
  text: "These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Site constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms of Use or use of the Site. You agree that these Terms of Use will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.",
};

export const CONTACT_US = {
  text: "In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at:",
  children: [
    {
      text: "System Provider:",
      children: [
        {
          text: "Amano McGann 2699 Patton Road Roseville, MN 55113 ."
        }
      ]
    },
    {
      text: "Parking Services Provider:",
      children: [
        {
          text: "(to be filled in when e-commerce site is created for each location)"
        }
      ]
    }
  ]
};

export const PAYMENTS = {
  text: "",
  children: [
    {
      text: "Price",
      children: [
        {
          text: "Your total price will include all Rates, Services and Taxes unless otherwise noted on Parking Rate Display Boards or by Parking Operator. All Taxes, if due shall be paid by Customer.",
        }
      ]
    },
    {
      text: "Payment",
      children: [
        {
          text: " CUSTOMER IS RESPONSBILE FOR PAYMENT OF ALL FEES DUE FOR PARKING AND OPTIONAL SERVICES AT TIME OF PAYMENT DUE AND CUSTOMER AGREES TO PROVIDE A VALID METHOD OF PAYMENT. FOR USE OF MOBILE PAYMENT CUSTOMER AGREES TO PAYMENT BY CREDIT CARD.",
        }
      ]
    }
  ]
};

export const RETURN_AND_REFUND_POLICY = {
  text: "",
  children: [
    {
      text: "REFUNDS",
      children: [
        {
          text: "All refunds are at the discretion of the operating party for the Parking Services rendered during your visit. Your purchase must be voided and/or refunded within 24 hours of payment. Please allow at least 3 days from the receipt of your payment to process your refund. Timeline can be different dependent on your bank’s activity and policies.",
        }
      ]
    }
  ]
};