import React from "react";
import Currency from "../Currency";
import { Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";

const TicketLineItems = ({ fees, validations }) => {
  const classes = useStyles();
  return (
    <Grid container className={clsx("ticket-line-item-list")}>
      {fees?.map((fee, index) => (
        <Grid
          container
          key={`fees-${index}`}
          data-type="fee"
          data-index={index}
          data-name={fee.name}
          data-value={fee.amount}
        >
          <Grid item xs={7}>
            <div className={clsx(classes.label)}>{fee.name}</div>
          </Grid>
          <Grid item xs={5}>
            <div className={clsx(classes.textEnd, classes.value)}>
              <Currency value={fee.amount}></Currency>
            </div>
          </Grid>
        </Grid>
      ))}
      {validations && validations.length > 0 && (
        <>
          {validations.map((val, index) => (
            <Grid
              container
              key={index}
              data-type="validation"
              data-index={(fees?.length ?? 0) + index}
              data-name={val.name}
              data-value={val.amount}
            >
              <Grid key={`validationsAppliedLbl-${index}`} item xs={7}>
                <div className={clsx(classes.label)}>{val.name}:</div>
              </Grid>
              <Grid key={index} item xs={5}>
                <div className={clsx(classes.textEnd, classes.value)}>
                  <Currency value={val.amount}></Currency>
                </div>
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

export default TicketLineItems;
