import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-duotone-svg-icons";
import { useStyles } from "./styles";
import clsx from "clsx";

const ValidUntilWarning = ({ rateValidUntil }) => {
  const classes = useStyles();
  const [minutesLeft, setMinutesLeft] = useState(0);
  const [expirationTime, setExpirationTime] = useState("");

  useEffect(() => {
    if (!rateValidUntil) {
      return;
    }

    var expireTimeDt = moment.utc(rateValidUntil).local();
    var startTime = moment().local();
    if (expireTimeDt <= startTime) {
      return;
    }

    setExpirationTime(expireTimeDt.toString());

    var minLeft = expireTimeDt.diff(startTime, "minutes");
    if (minLeft > 0) {
      setMinutesLeft(minLeft);
    } else {
      setMinutesLeft(0);
    }
  }, [rateValidUntil]);

  return (
    <>
      {minutesLeft > 0 && (
        <Grid item xs={12}>
          <div
            className={clsx(classes.flexCenter, "valid-until")}
            data-expiration-time={expirationTime}
            data-minutes-left={minutesLeft}
          >
            <FontAwesomeIcon icon={faExclamationTriangle} className={clsx(classes.warningIcon)} />
            <span data-id="FeeTimer">Exit within the next {minutesLeft} minutes.</span>
          </div>
        </Grid>
      )}
    </>
  );
};

export default ValidUntilWarning;
