import React from "react";
import { Grid } from "@material-ui/core";
import moment from "moment-timezone";
import clsx from "clsx";
import { useStyles } from "./styles";

const TicketDetails = ({ ticketId, startTime, endTime }) => {
  const classes = useStyles();
  
  const formatDt = (dt) => {
    return moment(dt).format("llll");
  };

  const trimedTicket = () => {
    return ticketId?.slice(ticketId.length - 12);
  };
  return (
    <>
      <Grid>
        <div
          data-value-full={ticketId}
          data-value={trimedTicket()}
          className={clsx(classes.label, classes.flexCenter, "ticket-id")}
        >
          Ticket ID:
          <span className={clsx(classes.paddingLeft, classes.ticketId)}>{trimedTicket()}</span>
        </div>
      </Grid>

      <Grid
        container
        data-id="TicketInfo"
        className={clsx(classes.spacing, "ticket-dates")}
        data-entry-date={formatDt(startTime)}
        data-exit-date={formatDt(endTime)}
      >
        <Grid item xs={4} className={clsx(classes.label)}>
          Entry Date
        </Grid>
        <Grid item xs={8} className={clsx(classes.textEnd, classes.value)}>
          {formatDt(startTime)}
        </Grid>
        <Grid item xs={4} className={clsx(classes.label)}>
          Exit Date
        </Grid>
        <Grid item xs={8} className={clsx(classes.textEnd, classes.value)}>
          {formatDt(endTime)}
        </Grid>
      </Grid>
    </>
  );
};

export default TicketDetails;
